// /* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import SvgBack from "../assets/Back";
import SvgNext from "../assets/Next";
import SliderColors from "./SliderColors";
import "./css/paginaProducto1.css";
import "./css/imgContainerProduct.css";
import { useCart } from "./hooks/useCart";

function ImgContainerProduct({ image }) {
    return (
        <div className="img-container-product">
            <div className="secondary-container" >
                {Array.isArray(image) && image.map((imageItem, index) => {
                    return (
                        <div key={index} className="img-container-2">
                            {
                                imageItem.description === "galeria" && (
                                    <img src={`data:image/png;base64,${imageItem.base64}`} alt={`imagen ${imageItem.description}`} className="secondary-image" />
                                )
                            }
                        </div>

                    );
                })}
            </div>

            {
                Array.isArray(image) && image.map((imageItem, index) => (
                    <div key={index} className="main-img-container">
                        {
                            imageItem.description === "principal" &&
                            <img src={`data:image/png;base64,${imageItem?.base64}`} className="big-img" alt={`imagen ${imageItem.description}`} />
                        }
                    </div>
                ))
            }
        </div>
    );
}

export default function PaginaProducto1({ product }) {
    const [cantidad, setCantidad] = useState(1);
    const { id } = useParams();
    const productID = parseInt(id);
    const { addToCart } = useCart();

    useEffect(() => {
        console.log(`ID del producto seleccionado: ${id}`);
    }, [id]);

    function increaseCount() {
        setCantidad(cantidad + 1);
    }

    function reduceCount() {
        if (cantidad > 1) {
            setCantidad(cantidad - 1);
        }
    }

    const producto = product.filter((p) => {
        return p.id === productID
    });

    function addToCartWithQuantity() {
        addToCart({
            ...producto[0],
            quantity: cantidad
        });
    }

    return (
        <>
            <div className="product-container">
                <div className="img-container">
                    <ImgContainerProduct image={producto[0]?.image} />
                    <p>Sin parabenos</p>
                </div>

                <div className="info-container">
                    {product.map((product, index) => (
                        product?.id === productID && (
                            <div key={index} className="info">
                                <div className="info-header">
                                    <p className="categoria">{product.parentCategoryName} <span className="negrita">/</span> {product.categorie} </p>
                                    <p>COD 17026</p>
                                </div>
                                <h2>{product.title}</h2>
                                <h3>{product.subtitle}</h3>
                                <p>{product.description}</p>
                                <p><span className="negrita">modo de uso:</span> {product.description}</p>
                                <p className="price">$ {product.price} ARS</p>
                                <SliderColors color={product.variants}></SliderColors>
                            </div>
                        )
                    ))}
                    <div className="zona-de-compra">
                        <input className="contador" type="text" value={cantidad} readOnly />
                        <div className="button-container">
                            <button className="button-color" onClick={increaseCount}><SvgBack height={14} width={14} fill={"red"} /></button>
                            <button className="button-color" onClick={reduceCount}><SvgNext height={14} width={14} fill={"red"} /></button>
                        </div>
                        <button onClick={addToCartWithQuantity} className="button">AÑADIR AL CARRITO</button>
                    </div>
                </div>
            </div>
        </>
    );
}
