import PaginaRegistro from "../PaginaRegistro";
import Header from "../Header"
import Footer from "../Footer";

export default function PaginaRegistroView() {
    return (
        <>
            <Header></Header>
            <PaginaRegistro></PaginaRegistro>
            <Footer></Footer>
        </>
    )
}