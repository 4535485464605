import { useContext, useEffect, useRef, useState } from "react"
import { FiltersContext } from "../context/FiltersContext.jsx"
import { getAll } from "../../../../services/abm.js"
// import productos from "../hooks/productMock.json"

export function useFilters() {
    const { filters, setFilters } = useContext(FiltersContext)
    const [product, setProduct] = useState([])

    function useProducts(product) {

        return product?.map(product => ({
            id: product.id,
            division: product.division,
            porpuse: product.purpose,
            price: product.purpose.publicPrice,
            title: product.purpose.primaryTitle,
            subtitle: product.purpose.secondaryTitle,
            image: product.photos,
            description: product.purpose.description,
            categories: product.categorie,
            categorie: product.categorie.name,
            parentCategory: product.categorie.parentCategory.parentCategory,
            parentCategoryName: product.categorie.parentCategory.name,
            variants: product.variants
        }))
    }

    const mapedProducts = useProducts(product)
    console.log(mapedProducts);

    const componentMounted = useRef(true)

    useEffect(() => {
        // setProduct(productos.products)
        if (filters.priceOrder === 0) {

            getAll("products/filters")
                .then((res) => {
                    if (componentMounted.current) {
                        console.log(res.products);
                        setProduct(res.products);
                    }

                })
                .catch((err) => console.log(err));

            return () => { // This code runs when component is unmounted
                componentMounted.current = false; // (4) set it to false when we leave the page
            }
        }
    }, [filters])


    function maxPrice(list) {

        list.sort((a, b) => {
            if (filters.priceOrder === 1)
                return a.price - b.price
            else if (filters.priceOrder === 2)
                return b.price - a.price
        })
    }
    maxPrice(mapedProducts)

    function filterProducts(product) {
        return mapedProducts.filter(product => {
            return (
                (
                    filters.priceOrder === 0 ||
                    filters.priceOrder === 1 ||
                    filters.priceOrder === 2
                )
                &&
                (
                    filters.category === "Todos los productos" ||
                    product.category === filters.category
                )
            )
        })
    }
    return { filterProducts, filters, setFilters, mapedProducts, maxPrice }

}