import React, { useEffect, useState } from "react";
import { Button, Carousel, Col, Container, Modal, Row, Tab, Tabs } from "react-bootstrap";
import { abm, removeImage } from "../../../services/abm";
import { ControlSelect, ControlTags, ControlText } from "../../helpers";
import { ControlFile } from "../../helpers/ControlFile";
import { ControlRadio } from "../../helpers/ControlRadio";
import { ControlTextarea } from "../../helpers/ControlTextarea";
import { AbmForm } from "../AbmForm";
import { Content } from "./personal/Content";
import { Prices } from "./personal/Prices";
import { PrincipalActives } from "./products/PrincipalActives";
import { Variants } from "./products/Variants";

export const ProductForm = ({
  item,
  schema,
  onCancel,
  onConfirm,
  confirmLabel = "Guardar",
  cancelLabel = "Cancelar",
  title,
  errorState,
}) => {
  const [modelosNegocio, setModelosNegocio] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [primaryCategories, setPrimaryCategories] = useState([]);
  const [secondaryCateogires, setSecondaryCategories] = useState([]);
  const [product, setProduct] = useState(() => item || {});

  const [showModalImages, setShowModalImages] = useState(false);
  const [imgDataPrincipal, setImgDataPrincipal] = useState(null);
  const [imgDataPortada, setImgDataPortada] = useState(null);
  const [imgsDataGaleria, setImgsDataGaleria] = useState(null);

  const [imgPrincipalId, setImgPrincipalId] = useState(null);
  const [imgPortadaId, setImgPortadaId] = useState(null);

  const base64toBlob = (imgBase64) => {
    const byteCharacters = atob(imgBase64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'multipart/form-data' });
    return blob;
  };

  const newFile = (img, name, type) => {
    const file = new File([img], name, {
      type: type,
    });
    return file;
  };

  useEffect(() => {
    abm("divisions")
      .getAll()
      .then((data) => {
        setDivisions(
          data.results.map((item) => ({ value: item.name, label: item.name }))
        );
      });

    abm("categories")
      .getAll()
      .then((data) => {
        const filterCategories = data.categories.filter(
          (category) => !!category.parentCategory && !!category.parentCategory.parentCategory
        );
        setCategories(filterCategories);
        setSecondaryCategories(
          filterCategories.map((item, index) => ({
            value: index,
            label: item.name,
          }))
        );
      });

    setModelosNegocio([
      { value: 1, label: "Modelo 1" },
      { value: 2, label: "Modelo 2" },
      { value: 3, label: "Modelo 3" },
      { value: 4, label: "Modelo 4" },
      { value: 5, label: "Modelo 5" },
    ]);

    if (item.photos !== undefined) {
      let galery = [];
      for (let i = 0; i < item.photos.length; i++) {
        if (item.photos[i].description === "principal") {
          setImgPrincipalId(item.photos[i].id);
          const imgPrincipalBlob = base64toBlob(item.photos[i].base64);
          const filePrincipal = newFile(imgPrincipalBlob, 'principal.png', imgPrincipalBlob.type);
          setImgDataPrincipal(filePrincipal);
        }
        else if (item.photos[i].description === "portada") {
          setImgPortadaId(item.photos[i].id);
          const imgPortadaBlob = base64toBlob(item.photos[i].base64);
          const filePortada = newFile(imgPortadaBlob, 'portada.png', imgPortadaBlob.type);
          setImgDataPortada(filePortada);
        }
        else if (item.photos[i].description === "galeria") {
          const imgGaleriaBlob = base64toBlob(item.photos[i].base64);
          const fileGaleria = newFile(imgGaleriaBlob, `galeria-${item.photos[i].id}.png`, imgGaleriaBlob.type);
          galery.push(fileGaleria);
        }
      }
      if (galery.length > 0) setImgsDataGaleria(galery);
    }
  }, []);

  useEffect(() => {
    if (product.categorySecondary !== undefined && secondaryCateogires.length > 0) {
      const index = secondaryCateogires.find((category) => (category.label === item.categorySecondary));

      // setProduct({...product, categorySecondary: index.value});


    }
  }, [secondaryCateogires])

  const changePrimaryCategories = async (onControlChange, event) => {
    const categoryIndex = event.target.value;
    if (
      categories[parseInt(categoryIndex)] &&
      categories[parseInt(categoryIndex)].parentCategory
    ) {
      console.log(categories[parseInt(categoryIndex)].parentCategory);
      onControlChange({
        target: {
          name: "categoryPrimary",
          value: categories[parseInt(categoryIndex)].parentCategory.id,
        },
      });
      setPrimaryCategories([
        {
          value: categories[parseInt(categoryIndex)].parentCategory.id,
          label: categories[parseInt(categoryIndex)].parentCategory.name,
        },
      ]);
    } else {
      onControlChange({
        target: { name: "categoryPrimary", value: undefined },
      });
      setPrimaryCategories([]);
    }
    if (categories[parseInt(categoryIndex)])
      onControlChange({
        target: {
          name: "categorySecondary",
          value: categoryIndex,
        },
      });
    else
      onControlChange({
        target: {
          name: "categorySecondary",
          value: undefined,
        },
      });
  };

  const handleShowModalImages = () => {
    setShowModalImages(true);
  };

  const handleCloseModalImages = () => {
    setShowModalImages(false);
  };

  const handleInputFileChange = (event, onInputChange) => {
    onInputChange(event);
    const target = event.target;
    if (target.name === "galeria") {
      if (target.value.length === 0) setImgsDataGaleria(null);
      else {
        const galeryImages = [...target.value];
        setImgsDataGaleria(galeryImages.map((image, index) => newFile(image, target.value[index].name, target.value[index].type)));
      }
    }
    else {
      if (target.value[0]) {
        if (target.name === "principal") {
          const filePrincipal = newFile(target.value[0], target.value[0].name, target.value[0].type);
          setImgDataPrincipal(filePrincipal);

        }
        else if (target.name === "portada") {
          const filePortada = newFile(target.value[0], target.value[0].name, target.value[0].type);
          setImgDataPortada(filePortada);
        }
      }
      else if (target.name === "principal") setImgDataPrincipal(null);
      else if (target.name === "portada") setImgDataPortada(null);
    }
  };

  const handleRemoveImage = (productId, imageId) => {
    removeImage(productId, imageId);
  };

  return (
    <AbmForm
      onSubmit={onConfirm}
      schema={schema}
      item={product}
      onCancel={onCancel}
      confirmLabel={confirmLabel}
      cancelLabel={cancelLabel}
      title={title}
      errorState={errorState}
      onInputFileChange={handleInputFileChange}
    >
      {({ onControlChange, entity, entityErrors }) => (
        <>
          <Row className="small-labels">
            <Col className="col-12 col-lg-4 mt-3">
              <p>
                <b>INFORMACIÓN PRINCIPAL</b>
              </p>
              <ControlText
                name="purpose.primaryTitle"
                label="Título primario"
                errors={entityErrors}
                data={entity}
                onChange={onControlChange}
                placeholder={"Título primario"}
                required
              />
              <ControlText
                name="purpose.secondaryTitle"
                label="Título secundario"
                errors={entityErrors}
                data={entity}
                onChange={onControlChange}
                placeholder={"Título secundario"}
              />
              <ControlSelect
                name="division"
                label="División"
                errors={entityErrors}
                data={entity}
                onChange={onControlChange}
                options={divisions}
                placeholder="Seleccioná una división"
              />
              <ControlRadio
                id="uso"
                name="uso"
                label="Uso"
                option1="Público"
                option2="Profesional"
                errors={entityErrors}
                data={entity}
                onChange={onControlChange}
              />
              <Content
                entityErrors={entityErrors}
                entity={entity}
                onControlChange={onControlChange}
              />
              <Prices
                entityErrors={entityErrors}
                entity={entity}
                onControlChange={onControlChange}
              />
              <ControlSelect
                name="categoryPrimary"
                label="Categoria"
                errors={entityErrors}
                data={entity}
                onChange={() => { }}
                options={primaryCategories}
                placeholder="Categoria primaria"
              />
              <ControlSelect
                name="categorySecondary"
                label=""
                errors={entityErrors}
                data={entity}
                onChange={(event) =>
                  changePrimaryCategories(onControlChange, event)
                }
                options={secondaryCateogires}
                placeholder="Categoria secundaria"
              />
              <ControlRadio
                id="variantes"
                label="Variantes"
                option1="Tiene variantes"
                option2="No tiene variantes"
                errors={entityErrors}
                data={entity}
                onChange={onControlChange}
              />
              <ControlRadio
                id="visibilidad"
                label="Visibilidad"
                option1="Publicado"
                option2="No publicado"
                errors={entityErrors}
                data={entity}
                onChange={onControlChange}
              />
            </Col>

            <Col className="col-12 col-lg-4 mt-3">
              <p>
                <b>INFORMACIÓN COMPLEMENTARIA</b>
              </p>
              <ControlTextarea
                name="purpose.description"
                label="Descripción"
                errors={entityErrors}
                data={entity}
                onChange={onControlChange}
                placeholder={"Descripción"}
                required
              />
              <ControlTextarea
                name="purpose.waysToUse"
                label="Modo de uso"
                errors={entityErrors}
                data={entity}
                onChange={onControlChange}
                placeholder={"Modo de uso"}
              />
              <ControlText
                name="purpose.code"
                label="Código de producto"
                errors={entityErrors}
                data={entity}
                type="number"
                onChange={onControlChange}
                placeholder={"Código de producto"}
              />
              <ControlTags
                name="details"
                label="Detalles"
                errors={entityErrors}
                data={entity}
                onChange={onControlChange}
              />

              <Button onClick={handleShowModalImages}>Añadir imágenes</Button>
              <Modal centered keyboard={false} backdrop={imgDataPrincipal ? true : "static"} show={showModalImages} onHide={handleCloseModalImages}>
                <Container style={{ minHeight: "300px" }}>
                  <Tabs
                    defaultActiveKey="principal"
                    id="images-tabs"
                    className="mb-3"
                    justify
                  >
                    <Tab eventKey="principal" title="Imagen principal">
                      <Container className="my-3">
                        <ControlFile
                          id="principal"
                          errors={entityErrors}
                          data={entity}
                          onChange={(event) =>
                            handleInputFileChange(event, onControlChange)
                          }
                          onRemove={() => handleRemoveImage(item.id, imgPrincipalId)}
                          filesNames={imgDataPrincipal ? [imgDataPrincipal.name] : []}
                        />
                        {
                          imgDataPrincipal &&
                          <Row className="justify-content-center mt-5">
                            <Container style={{ height: "300px" }}>
                              <img
                                src={URL.createObjectURL(imgDataPrincipal)}
                                className="p-0"
                                alt="Imagen principal"
                                width="100%"
                                height="100%"
                                style={{ objectFit: "contain" }}
                              />
                            </Container>
                          </Row>
                        }
                      </Container>
                    </Tab>
                    <Tab eventKey="galeria" title="Galería">
                      <Container className="my-3">
                        <ControlFile
                          id="galeria"
                          multiple={true}
                          optional={true}
                          errors={entityErrors}
                          data={entity}
                          onChange={(event) =>
                            handleInputFileChange(event, onControlChange)
                          }
                          filesNames={imgsDataGaleria ? imgsDataGaleria?.map(img => img.name) : []}
                        />
                        {imgsDataGaleria ?
                          <Carousel variant="dark" indicators={false}>
                            {imgsDataGaleria.map((imgDataGaleria, index) => (
                              <Carousel.Item key={index} style={{ height: "300px" }}>
                                <img
                                  src={URL.createObjectURL(imgDataGaleria)}
                                  className="d-block"
                                  alt={`Imagen ${index + 1} de galería`}
                                  width="100%"
                                  height="100%"
                                  style={{ objectFit: "contain" }}
                                />
                              </Carousel.Item>
                            ))}
                          </Carousel>
                          : null
                        }
                      </Container>
                    </Tab>
                    <Tab eventKey="portada" title="Portada">
                      <Container className="my-3">
                        <ControlFile
                          id="portada"
                          optional={true}
                          errors={entityErrors}
                          data={entity}
                          onChange={(event) =>
                            handleInputFileChange(event, onControlChange)
                          }
                          onRemove={() => handleRemoveImage(item.id, imgPortadaId)}
                          filesNames={imgDataPortada ? [imgDataPortada.name] : []}
                        />
                        {
                          imgDataPortada &&
                          <Row className="justify-content-center">
                            <Container style={{ height: "300px" }}>
                              <img
                                src={URL.createObjectURL(imgDataPortada)}
                                className="p-0"
                                alt="Imagen de portada"
                                width="100%"
                                height="100%"
                                style={{ objectFit: "contain" }}
                              />
                            </Container>
                          </Row>
                        }
                      </Container>
                    </Tab>
                  </Tabs>
                  <div className="ps-1 mb-3">
                    <Button
                      variant="primary"
                      className="ms-2"
                      style={{ color: "white", borderRadius: "25px", minWidth: "112px" }}
                      onClick={handleCloseModalImages}
                      disabled={imgDataPrincipal === null}
                    >
                      Confirmar
                    </Button>
                  </div>
                </Container>
              </Modal>

            </Col>

            <Col className="col-12 col-lg-4 mt-3">
              <p className="opacity-0">
                <b>INFO</b>
              </p>

              <PrincipalActives
                entityErrors={entityErrors}
                entity={entity}
                onControlChange={onControlChange}
              />

              <Variants
                item={item}
                entityErrors={entityErrors}
                entity={entity}
                onControlChange={onControlChange}
              />
              {/* <Button onClick={handleShowModalImages}>Añadir imágenes</Button>
              <ImageUploaderModal
                showModal={showModalImages}
                handleClose={handleCloseModalImages}
                handleInputFileChange={handleInputFileChange}
                handleRemoveImage={handleRemoveImage}
                imgDataPrincipal={imgDataPrincipal}
                imgDataPortada={imgDataPortada}
                imgsDataGaleria={imgsDataGaleria}
                imgPrincipalId={imgPrincipalId}
                imgPortadaId={imgPortadaId}
                entityErrors={entityErrors}
                entity={entity}
                onControlChange={onControlChange}
                item={item}
              /> */}
            </Col>
          </Row>
        </>
      )}
    </AbmForm>
  );
};
