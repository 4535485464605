/* eslint-disable react/prop-types */
import { useState } from "react";
import SvgBack from "../assets/Back";
import SvgNext from "../assets/Next";
import estilos from "./css/slider2.css"
import { useNavigate } from 'react-router-dom';
import { PRODUCTS_PAGE } from '../../../config/router/routes';
// import product11 from "../assets/product-11.svg"
// import product12 from "../assets/product-12.svg"

export const ImgContainer = ({ imagen, description, titulos, onClick }) => {
    // const imagenFiltered = imagen.filter((img) => img.description === "principal")
    return (
        <div className="img-container-2">
            <div className="text-container">
                <p>{description}</p>
                <h3>{titulos}</h3>
                <button onClick={onClick}>VER PRODUCTOS</button>
            </div>
            {/* <img src={`data:image/png;base64,${imagen.base64}`} alt="imagen" /> */}
            <img src={`data:image/png;base64,${imagen.base64}`} alt="imagen" />
            {/* <img src={`data:image/png;base64,${imagenFiltered[0].base64}`} alt="imagen" /> */}
        </div>
    )
}

export default function Slider2({ product }) {
    // console.log(product);
    const [imagenActual, setImagenActual] = useState(1);
    const cantidad = product?.length;
    const navigate = useNavigate()
    const affectedProductsId = []
    const siguienteImagen = () => {
        setImagenActual(imagenActual === cantidad - 1 ? 2 : imagenActual + 1);
    };

    const anteriorImagen = () => {
        setImagenActual(imagenActual === 2 ? cantidad - 1 : imagenActual - 1);
    };

    const redirectPromos = (p) => {
        if (!Array.isArray(p.affectedProducts)) {
            console.error("affectedProducts no es un array");
            return;
        }
        p.affectedProducts.forEach((product) => {
            affectedProductsId.push(product.id);
        });
        navigate(`/${PRODUCTS_PAGE}/${affectedProductsId.join(',')}`)
    }

    return (
        <div className="slider-container-2">
            <button onClick={anteriorImagen}><SvgBack height={15} width={15} fill={"red"}></SvgBack></button>
            {product?.map((product, index) => {
                return (
                    <div key={index}
                        className={
                            imagenActual === index
                                ? `${estilos.slide} ${estilos.active}`
                                : estilos.slide
                        }>
                        {imagenActual === index && (
                            <ImgContainer onClick={() => redirectPromos(product)} imagen={product.image} titulos={product.divisionName} description={product.categorie}></ImgContainer>
                        )}
                        {imagenActual === index + 1 && (
                            <ImgContainer onClick={() => redirectPromos(product)} imagen={product.image} titulos={product.divisionName} description={product.categorie}></ImgContainer>
                        )}
                    </div>
                );
            })}
            <button onClick={siguienteImagen}><SvgNext height={15} width={15} fill={"red"}></SvgNext></button>
        </div>
    )
}
