import { useForm } from "react-hook-form";
import "./css/paginaRegistro.css"
import styles from './login.module.css';
import { FileUpload } from "./FileUpload";
import { routes } from "../../../config/constants";
import { useState } from "react";
import { HOME_LANDING, REGISTRO } from "../../../config/router/routes";

export const RegisterModal = ({ isOpen, openModal, closeModal }) => {
    const handleBackdropClick = (e) => {
        if (!e.target.closest(`.${styles.modalContent}`)) {
            closeModal();
        }
    };
    const handleModalClick = (e) => {
        e.stopPropagation();
    };

    return (
        <div>
            {isOpen && (
                <div className={styles.modalWrapper} onClick={handleBackdropClick}>
                    <div className={styles.modal} onClick={handleModalClick}>
                        <button className={styles.modalCloseButton} onClick={closeModal}> X </button>
                        <h2 className={styles.title}>Acceso para profesionales</h2>
                        <p className={styles.subText}>
                            Tus datos se enviaron correctamente, tu solicitud será
                            revisada en breve para aprobar tu registro.
                            Revisa tu bandeja de entrada para recibir la confirmación
                            de tu cuenta y adquirir tu <span className={styles.negrita}> código profesional exclusivo </span>
                            con el cual podrás cobrar tu comisión por cada compra
                            que realicen tus clientes.
                        </p>
                        <div className={styles.buttonContainer}>
                            <button className={styles.buttonPrimary} type='submit'>CONTINUAR NAVEGANDO</button>
                            <button className={styles.buttonSecondary}><a className={styles.links} href={HOME_LANDING}>IR A BANDEJA DE ENTRADA</a></button>
                        </div>
                        <p className={styles.linksContainer}>¿No sos usuario? <a href={REGISTRO} className={styles.links} > ¡Registrate aqui!</a></p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default function PaginaRegistro() {
    const { register, handleSubmit, setValue, getValues, formState: { errors } } = useForm({
        mode: 'onBlur',
    });

    const [isOpen, setIsOpen] = useState(false);

    const profesiones = ["COSMETOLOGA",
        "COSMIATRA",
        "ESTETICISTA CORPORAL",
        "MASAJISTA",
        "MAQUILLADORA",
        "MANICURA",
        "LASHISTA",
        "ESTILISTA"]

    const openModal = () => {
        setIsOpen(true);
    };
    const closeModal = () => {
        setIsOpen(false);
    };

    const enviarInfo = async (values) => {
        console.log(values);
        const url = routes.signup;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                ...values,
                passwordVerification: "1234",
                userType: "PUBLIC_USER",
                openTime: "12:00:00",
                closeTime: "18:00:00",
                debitAllowed: false,
                creditAllowed: false,
                salePoint: false,
                emailUpdates: false,
            }),
        })
            .then((res) => {
                res.json();
            })
            .catch((err) => console.log(`[ERROR - login publico - 105] ${err}`));
    }

    return (
        <div className='registro-container'>
            <p className="section-area">EXCLUSIVO PROFESIONAL</p>
            <h2 className="title-registro">Registro de profesionales</h2>
            <p className="gris">Si aún no te registraste, podes hacerlo ahora en tres simples pasos.
                ¡Accedé a todos los beneficios que Idraet Professional VIP tiene para vos!</p>

            <form className="form-registro-container" onSubmit={handleSubmit(enviarInfo)}>
                <h3 className="subtitle">DATOS PERSONALES</h3>
                <div className="form-registro">
                    <input className="input-form"
                        type="text"
                        placeholder="Nombre"
                        {...register("firstName")}
                    />
                    <input className="input-form"
                        type="text"
                        placeholder="Apellido"
                        {...register("lastName")}
                    />
                    <input className="input-form"
                        type="text"
                        placeholder="Fecha de nacimiento"
                        {...register("birthDate")}
                    />
                    <input className="input-form"
                        type="text"
                        placeholder="DNI"
                        {...register("identificationNumber")}
                    />
                    <input className="input-form"
                        type="text"
                        placeholder="Email"
                        {...register("email", {
                            pattern: /^(([^<>()\[\]\\.,;:\s@”]+(\.[^<>()\[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/
                        })}
                    />
                    {errors.Email?.type === "pattern" && <p className="error">introduce un email valido</p>}

                    <input className="input-form"
                        type="password"
                        placeholder="Contraseña"
                        {...register("password")}
                    />
                    <FileUpload
                        register={register}
                    ></FileUpload>
                    <div className="radio-input">
                        <input type="radio" id="value-1" name="value-radio" value="masculino" {...register('genero')} />
                        <div className="circle"></div>
                        <label>masculino</label>
                        <input type="radio" id="value-2" name="value-radio" value="femenino" {...register('genero')} />
                        <div className="circle"></div>
                        <label>Femenino</label>
                        <input type="radio" id="value-3" name="value-radio" value="otro" {...register('genero')} />
                        <div className="circle"></div>
                        <label>Otro</label>
                    </div>
                    <select name="hasta" id="hasta" placeholder="Profesiones">
                        <option value="" disabled>Profesiones</option>
                        {profesiones.map(profesion => (
                            <option value={profesion}>{profesion}</option>
                        ))}
                    </select>
                    <input className="input-form"
                        type="text"
                        placeholder="codigo profesional"
                        {...register("codigo")}
                    />
                </div>
                <h3 className="subtitle">DATOS DE UBICACIÓN</h3>
                <div className="form-registro">
                    <input className="input-form"
                        type="text"
                        placeholder="Calle"
                        {...register("street")}
                    />
                    <input className="input-form"
                        type="text"
                        placeholder="Altura"
                        {...register("externalNumber")}
                    />
                    <input className="input-form"
                        type="text"
                        placeholder="CP"
                        {...register("zipCode")}
                    />
                    <input className="input-form"
                        type="text"
                        placeholder="Provincia"
                        {...register("province")}
                    />
                    <input className="input-form"
                        type="text"
                        placeholder="Ciudad"
                        {...register("city")}
                    />
                    <input className="input-form"
                        type="text"
                        placeholder="Barrio"
                        {...register("town")}
                    />

                </div>
                <button type="submit" className="submit-button" onClick={openModal}>Enviar</button>
            </form>
            <RegisterModal isOpen={isOpen} openModal={openModal} closeModal={closeModal}></RegisterModal>
        </div>
    )
}
