import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import { getAll, create, update, remove } from "../../services/abm";
import AddCategoryModal from "../../components/abm/categories/AddCategoryModal";
import EditCategoryModal from "../../components/abm/categories/EditCategoryModal";
import ModalDelete from "../../components/modals/ModalDelete";
import CategoriesTable from "../../components/abm/categories/CategoriesTable";
import SubcategoriesTable from "../../components/abm/categories/SubcategoriesTable";
import Pagination from "../../components/abm/Pagination";
import { BsSearch } from "react-icons/bs";
import { PrivateLayoutContext } from "../../components/layout/PrivateLayout";
import CategoriesTable3 from "../../components/abm/categories/CategoriesTable3";
import { set } from "object-path-immutable";

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [categoryType, setCategoryType] = useState("cat");


  const categoriesWithoutParent = categories.filter(
    (category) => category.parentCategory === null
  );

  const subcategories = categories.filter(
    (category) => category.parentCategory !== null && category.parentCategory.parentCategory === null
  );

  const Categories3 = categories.filter(
    (category) => category.parentCategory !== null && category.parentCategory.parentCategory !== null && category.parentCategory.parentCategory.parentCategory === null
  );

  const Categories4 = categories.filter(
    (category) => category.parentCategory !== null && category.parentCategory.parentCategory !== null && category.parentCategory.parentCategory.parentCategory !== null
  );

  const initialSelectedCategory = {
    id: "",
    name: "",
    isPublic: false,
    isProfessional: false,
    sector: {
      id: "",
      name: ""
    }
  };

  const [selectedCategory, setSelectedCategory] = useState(
    initialSelectedCategory
  );
  const [selectedParentCategoryId, setSelectedParentCategoryId] = useState(0);

  const [currentTableCategories, setCurrentTableCategories] = useState(0);
  const [currentTableSubcategories, setCurrentTableSubcategories] = useState(0);
  const [currentTableCategories3, setCurrentTablecategories3] = useState(0);
  const [currentTableCategories4, setCurrentTableCategories4] = useState(0);

  const [searchCategory, setSearchCategory] = useState("");
  const [searchSubcategory, setSearchSubcategory] = useState("");
  const [searchCategory3, setSearchCategory3] = useState("");
  const [searchCategory4, setSearchCategory4] = useState("");
  const [refresh, setRefresh] = useState(false)
  const { setHeaderTitle, setBreadcrumb } = useContext(PrivateLayoutContext);
  console.log(refresh);
  const handleRefresh = () => {
    setTimeout(() => {
      if (refresh === false) {
        setRefresh(true)
      }
      if (refresh === true) {
        setRefresh(false)
      }
    }, 2000);
  }
  const filteredCategoriesWithoutParent = () => {
    if (searchCategory.length === 0)
      return categoriesWithoutParent.slice(
        currentTableCategories,
        currentTableCategories + 5
      );
    else {
      const filtered = categoriesWithoutParent.filter((category) =>
        category.name.toLowerCase().includes(searchCategory.toLowerCase())
      );
      return filtered.slice(currentTableCategories, currentTableCategories + 5);
    }
  };

  const filteredSubcategories = () => {
    if (searchSubcategory.length === 0)
      return subcategories.slice(
        currentTableSubcategories,
        currentTableSubcategories + 5
      );
    else {
      const filtered = subcategories.filter((subcategory) =>
        subcategory.name.toLowerCase().includes(searchSubcategory.toLowerCase())
      );
      return filtered.slice(
        currentTableSubcategories,
        currentTableSubcategories + 5
      );
    }
  };

  const filteredCategories3 = () => {
    if (searchCategory3.length === 0)
      return Categories3.slice(
        currentTableCategories3,
        currentTableCategories3 + 5
      );
    else {
      const filtered = Categories3.filter((subcategory) =>
        subcategory.name.toLowerCase().includes(searchCategory3.toLowerCase())
      );
      return filtered.slice(
        currentTableCategories3,
        currentTableCategories3 + 5
      );
    }
  };

  const filteredCategories4 = () => {
    if (searchCategory4.length === 0)
      return Categories4.slice(currentTableCategories4, currentTableCategories4 + 5);
    else {
      const filtered = Categories4.filter((subcategory) =>
        subcategory.name.toLowerCase().includes(searchCategory4.toLowerCase())
      );
      return filtered.slice(currentTableCategories4, currentTableCategories4 + 5);
    }
  };

  const handleShowAddModal = () => {
    setShowAddModal(true);
  };

  const handleCloseAddModal = () => {
    setShowAddModal(false);
  };

  /* const handleShowEditModal = () => setShowEditModal(true); //no se usa por ahora */
  const handleCloseEditModal = () => {
    setSelectedCategory(initialSelectedCategory);
    setCategoryType("cat");
    setShowEditModal(false);
  };

  /* const handleShowDeleteModal = () => setShowDeleteModal(true); //no se usa por ahora */
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const handlePrevTableCategories = () => {
    if (currentTableCategories > 0)
      setCurrentTableCategories(currentTableCategories - 5);
  };

  const handleNextTableCategories = () => {
    setCurrentTableCategories(currentTableCategories + 5);
  };

  const handlePrevTableSubcategories = () => {
    if (currentTableSubcategories > 0)
      setCurrentTableSubcategories(currentTableSubcategories - 5);
  };

  const handleNextTableSubcategories = () => {
    setCurrentTableSubcategories(currentTableSubcategories + 5);
  };

  const handlePrevTableCategories3 = () => {
    if (currentTableSubcategories > 0)
      setCurrentTableSubcategories(currentTableSubcategories - 5);
  };

  const handleNextTableCategories3 = () => {
    setCurrentTableSubcategories(currentTableSubcategories + 5);
  };

  const handlePrevTableCategories4 = () => {
    if (currentTableSubcategories > 0)
      setCurrentTableSubcategories(currentTableSubcategories - 5);
  };

  const handleNextTableCategories4= () => {
    setCurrentTableSubcategories(currentTableSubcategories + 5);
  };

  const handleSearchCategoryChange = (event) => {
    setCurrentTableCategories(0);
    setSearchCategory(event.target.value);
  };

  const handleSearchSubcategoryChange = (event) => {
    setCurrentTableSubcategories(0);
    setSearchSubcategory(event.target.value);
  };

  const add = (name, parentCategoryId, sector, isPublic, isProfessional) => {
    if (categories.some((category) => category.name === name))
      return alert(`Ya existe una categoría llamada ${name}`);
    if (parentCategoryId === null) {
      const newCategory = {
        name,
        isPublic,
        isProfessional,
        sector: {
          name: sector
        },
        parentCategory: null,
      };
      create(newCategory, "categories");
    }
    else {
      const newSubcategory = {
        name,
        parentCategory: {
          id: parentCategoryId,
        },
        isPublic,
        sector: {
          name: sector
        },
      };
      create(newSubcategory, "categories");
    }
  };

  const select = (category, type) => {
    setSelectedCategory(category);
    if (type === "edit") {
      if (category.parentCategory !== null) {
        setCategoryType("sub");
        setSelectedParentCategoryId(category.parentCategory.id);
      }
      setShowEditModal(true);
    } else if (category.childCategories === null) setShowDeleteModal(true);
    else
      alert(
        `Primero debés eliminar las subcategorías asociadas a la categoría ${category.name}`
      );
  };

  const edit = (name, parentCategoryId, isPublic, sector) => {
    const categoriesWithoutCategoryToEdit = categories.filter(
      (category) => category.name !== selectedCategory.name
    );

    if (
      categoriesWithoutCategoryToEdit.some((category) => category.name === name)
    )
      return alert(`Ya existe una categoría llamada ${name}`);
    else {
      if (parentCategoryId === "" || parentCategoryId === null) {
        const editedCategory = {
          name,
          parentCategory: null,
          isPublic,
          sector: {
            name: sector
          }
        };
        update(editedCategory, selectedCategory.id, "categories", "PUT");
        setSelectedParentCategoryId(0);
      } else {
        const editedSubcategory = {
          name,
          parentCategory: {
            id: parentCategoryId,
          },
          isPublic,
          sector: {
            name: sector
          }
        };
        update(editedSubcategory, selectedCategory.id, "categories", "PUT");
        setSelectedParentCategoryId(0);
      }
    }
  };

  const removeCategory = () => {
    remove(selectedCategory.id, "categories");
  };

  useEffect(() => {
    setHeaderTitle("Categorías y subcategorías");
    setBreadcrumb([
      { caption: "Categorias y subcategorías", href: "#" },
      { caption: "Administrar", href: "#" }
    ]);

    getAll("categories")
      .then((res) => {
        setCategories(res.categories);
      })
      .catch((err) => console.log(err));
    getAll("sectors")
      .then((data) => {
        setSectors(
          data.results.map((item) => ({ value: item.name, label: item.name }))
        );
      });
  }, [refresh]);

  return (
    <Container className="mt-3">
      <main>
        <Row>
          <Col className="text-end">
            <Button variant="secondary" onClick={handleShowAddModal}>
              Nueva
            </Button>
          </Col>
        </Row>
        <section className="my-4">
          <Row className=" justify-content-end">
            <Col>
              <h2>Categorías primarias</h2>
            </Col>
          </Row>
          <hr />

          <InputGroup id="search-category" className="mb-3">
            <FormControl
              type="text"
              placeholder="Buscar categoría"
              aria-label="Buscar categoria"
              aria-describedby="search-category"
              value={searchCategory}
              onChange={handleSearchCategoryChange}
            />
            <InputGroup.Text>
              <BsSearch />
            </InputGroup.Text>
          </InputGroup>

          <CategoriesTable
            categories={filteredCategoriesWithoutParent()}
            onClick={select}

          />

          <Pagination
            onPrev={handlePrevTableCategories}
            onNext={handleNextTableCategories}
            disabledButtonPrev={currentTableCategories === 0}
            disabledButtonNext={filteredCategoriesWithoutParent().length < 5}
          />
        </section>

        <section className="my-4">
          <Row className=" justify-content-end">
            <Col>
              <h2>Categorías secundarias</h2>
            </Col>
          </Row>
          <hr />

          <InputGroup id="search-subcategory" className="mb-3">
            <FormControl
              type="text"
              placeholder="Buscar subcategoría"
              aria-label="Buscar subcategoria"
              aria-describedby="search-subcategory"
              value={searchSubcategory}
              onChange={handleSearchSubcategoryChange}
            />
            <InputGroup.Text>
              <BsSearch />
            </InputGroup.Text>
          </InputGroup>

          <SubcategoriesTable
            subcategories={filteredSubcategories()}
            onClick={select}
          />
          <Pagination
            onPrev={handlePrevTableSubcategories}
            onNext={handleNextTableSubcategories}
            disabledButtonPrev={currentTableSubcategories === 0}
            disabledButtonNext={filteredSubcategories().length < 5}
          />
        </section>

        <section className="my-4">
          <Row className=" justify-content-end">
            <Col>
              <h2>Categorías terciarias</h2>
            </Col>
          </Row>
          <hr />

          <InputGroup id="search-subcategory" className="mb-3">
            <FormControl
              type="text"
              placeholder="Buscar subcategoría"
              aria-label="Buscar subcategoria"
              aria-describedby="search-subcategory"
              value={searchSubcategory}
              onChange={handleSearchSubcategoryChange}
            />
            <InputGroup.Text>
              <BsSearch />
            </InputGroup.Text>
          </InputGroup>


          <CategoriesTable3
            subcategories={filteredCategories3()}
            onClick={select}
          />

          <Pagination
            onPrev={handlePrevTableCategories3}
            onNext={handleNextTableCategories3}
            disabledButtonPrev={currentTableCategories3 === 0}
            disabledButtonNext={filteredCategories3.length < 5}
          />
        </section>

        <section className="my-4">
          <Row className=" justify-content-end">
            <Col>
              <h2>Categorías cuaternarias</h2>
            </Col>
          </Row>
          <hr />

          <InputGroup id="search-subcategory" className="mb-3">
            <FormControl
              type="text"
              placeholder="Buscar categoría cuaternaria"
              aria-label="Buscar categoría cuaternaria"
              aria-describedby="search-subcategory"
              value={searchSubcategory}
              onChange={handleSearchSubcategoryChange}
            />
            <InputGroup.Text>
              <BsSearch />
            </InputGroup.Text>
          </InputGroup>

          <CategoriesTable3
            subcategories={filteredCategories4()}
            onClick={select}
          />

          <Pagination
            onPrev={() => setCurrentTableCategories4((prev) => Math.max(0, prev - 5))}
            onNext={() => setCurrentTableCategories4((prev) => prev + 5)}
            disabledButtonPrev={currentTableCategories4 === 0}
            disabledButtonNext={filteredCategories4().length < 5}
          />
        </section>
      </main>

      <AddCategoryModal
        categories={categoriesWithoutParent}
        categories2={subcategories}
        categories3={Categories3}
        show={showAddModal}
        close={handleCloseAddModal}
        add={add}
        refresh={handleRefresh}
      />

      <EditCategoryModal
        type={categoryType}
        categories={categoriesWithoutParent}
        categoryName={selectedCategory.name}
        parentCategoryId={selectedParentCategoryId}
        categoryIsPublic={selectedCategory.isPublic}
        categorySector={selectedCategory.sector.name}
        sectors={sectors}
        show={showEditModal}
        close={handleCloseEditModal}
        edit={edit}
      />

      <ModalDelete
        title={
          selectedCategory.parentCategory === null
            ? "categoría"
            : "subcategoría"
        }
        show={showDeleteModal}
        close={handleCloseDeleteModal}
        remove={removeCategory}
      >
        {`¿Estás seguro/a que querés eliminar la ${selectedCategory.parentCategory === null
          ? "categoría"
          : "subcategoría"
          } 
        ${selectedCategory.name}?`}
      </ModalDelete>
    </Container>
  );
};

export default Categories;
