import { useState, useEffect } from 'react';
import { SimpleControlText } from '../../../helpers/SimpleControlText';
import { ListControl } from './ListControl';
import { Button, Modal, Container, Tabs, Tab, Row, Carousel } from 'react-bootstrap';
import { ControlFile } from '../../../helpers/ControlFile';
import { removeImage } from '../../../../services/abm';

const AddImg = ({ onControlChange, entity, entityErrors, item, updateVariantImages }) => {
  const [showModalImages, setShowModalImages] = useState(false);
  const [imgDataPrincipal, setImgDataPrincipal] = useState(null);
  const [imgDataPortada, setImgDataPortada] = useState(null);
  const [imgsDataGaleria, setImgsDataGaleria] = useState(null);
  const [imgPrincipalId, setImgPrincipalId] = useState(null);
  const [imgPortadaId, setImgPortadaId] = useState(null);

  const base64toBlob = (imgBase64) => {
    const byteCharacters = atob(imgBase64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'multipart/form-data' });
    return blob;
  };

  useEffect(() => {
    if (item.photos !== undefined) {
      let galery = [];
      for (let i = 0; i < item.photos.length; i++) {
        if (item.photos[i].description === "principal") {
          setImgPrincipalId(item.photos[i].id);
          const imgPrincipalBlob = base64toBlob(item.photos[i].base64);
          const filePrincipal = newFile(imgPrincipalBlob, 'principal.png', imgPrincipalBlob.type);
          setImgDataPrincipal(filePrincipal);
        }
        else if (item.photos[i].description === "portada") {
          setImgPortadaId(item.photos[i].id);
          const imgPortadaBlob = base64toBlob(item.photos[i].base64);
          const filePortada = newFile(imgPortadaBlob, 'portada.png', imgPortadaBlob.type);
          setImgDataPortada(filePortada);
        }
        else if (item.photos[i].description === "galeria") {
          const imgGaleriaBlob = base64toBlob(item.photos[i].base64);
          const fileGaleria = newFile(imgGaleriaBlob, `galeria-${item.photos[i].id}.png`, imgGaleriaBlob.type);
          galery.push(fileGaleria);
        }
      }
      if (galery.length > 0) setImgsDataGaleria(galery);
    }
  }, []);

  const newFile = (img, name, type) => {
    const file = new File([img], name, {
      type: type,
    });
    return file;
  };

  const handleShowModalImages = () => {
    setShowModalImages(true);
  };

  const handleCloseModalImages = () => {
    setShowModalImages(false);
  };

  const handleInputFileChange = (event, onInputChange) => {
    // onInputChange(event);
    const target = event.target;
    if (target.name === "galeria") {
      if (target.value.length === 0) setImgsDataGaleria(null);
      else {
        const galeryImages = [...target.value];
        // setImgsDataGaleria(galeryImages.map((image, index) => newFile(image, target.value[index].name, target.value[index].type)));
        const newImgsDataGaleria = galeryImages.map((image, index) => newFile(image, target.value[index].name, target.value[index].type));
        setImgsDataGaleria(newImgsDataGaleria);
        updateVariantImages({ imgDataPrincipal, imgDataPortada, imgsDataGaleria: newImgsDataGaleria })
      }
    }
    else {
      if (target.value[0]) {
        if (target.name === "principal") {
          const filePrincipal = newFile(target.value[0], target.value[0].name, target.value[0].type);
          setImgDataPrincipal(filePrincipal);
          updateVariantImages({ imgDataPrincipal: filePrincipal, imgDataPortada, imgsDataGaleria });
        }
        else if (target.name === "portada") {
          const filePortada = newFile(target.value[0], target.value[0].name, target.value[0].type);
          setImgDataPortada(filePortada);
          updateVariantImages({ imgDataPrincipal, imgDataPortada: filePortada, imgsDataGaleria });
        }
      }
      // else if (target.name === "principal") setImgDataPrincipal(null);
      // else if (target.name === "portada") setImgDataPortada(null);
      else if (target.name === "principal") {
        setImgDataPrincipal(null);
        updateVariantImages({ imgDataPrincipal: null, imgDataPortada, imgsDataGaleria });
      }
      else if (target.name === "portada") {
        setImgDataPortada(null);
        updateVariantImages({ imgDataPrincipal, imgDataPortada: null, imgsDataGaleria });
      }
    }
  };

  const handleRemoveImage = (productId, imageId) => {
    removeImage(productId, imageId);
  };

  // console.log(item);
  // console.log(imgsDataGaleria);
  return (
    <>
      <Button className='mt-3' onClick={handleShowModalImages} style={{ width: "150px" }}>Añadir imágenes</Button>
      <Modal centered keyboard={false} backdrop={imgDataPrincipal ? true : "static"} show={showModalImages} onHide={handleCloseModalImages}>
        <Container style={{ minHeight: "300px" }}>
          <Tabs defaultActiveKey="principal" id="images-tabs" className="mb-3" justify>
            <Tab eventKey="principal" title="Imagen principal">
              <Container className="my-3">
                <ControlFile
                  id="principal"
                  errors={entityErrors}
                  data={entity}
                  onChange={(event) =>
                    handleInputFileChange(event, onControlChange)
                  }
                  onRemove={() => handleRemoveImage(item.id, imgPrincipalId)}
                  filesNames={imgDataPrincipal ? [imgDataPrincipal.name] : []}
                />
                {
                  imgDataPrincipal &&
                  <Row className="justify-content-center mt-5">
                    <Container style={{ height: "300px" }}>
                      <img
                        src={URL.createObjectURL(imgDataPrincipal)}
                        className="p-0"
                        alt="Imagen principal"
                        width="100%"
                        height="100%"
                        style={{ objectFit: "contain" }}
                      />
                    </Container>
                  </Row>
                }
              </Container>
            </Tab>
            <Tab eventKey="galeria" title="Galería">
              <Container className="my-3">
                <ControlFile
                  id="galeria"
                  multiple={true}
                  optional={true}
                  errors={entityErrors}
                  data={entity}
                  onChange={(event) =>
                    handleInputFileChange(event, onControlChange)
                  }
                  filesNames={imgsDataGaleria ? imgsDataGaleria?.map(img => img.name) : []}
                />
                {imgsDataGaleria ?
                  <Carousel variant="dark" indicators={false}>
                    {imgsDataGaleria.map((imgDataGaleria, index) => (
                      <Carousel.Item key={index} style={{ height: "300px" }}>

                        <img
                          src={URL.createObjectURL(imgDataGaleria)}
                          className="d-block"
                          alt={`Imagen ${index + 1} de galería`}
                          width="100%"
                          height="100%"
                          style={{ objectFit: "contain" }}
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                  : null
                }
              </Container>
            </Tab>
            <Tab eventKey="portada" title="Portada">
              <Container className="my-3">
                <ControlFile
                  id="portada"
                  optional={true}
                  errors={entityErrors}
                  data={entity}
                  onChange={(event) =>
                    handleInputFileChange(event, onControlChange)
                  }
                  onRemove={() => handleRemoveImage(item.id, imgPortadaId)}
                  filesNames={imgDataPortada ? [imgDataPortada.name] : []}
                />
                {
                  imgDataPortada &&
                  <Row className="justify-content-center">
                    <Container style={{ height: "300px" }}>
                      <img
                        src={URL.createObjectURL(imgDataPortada)}
                        className="p-0"
                        alt="Imagen de portada"
                        width="100%"
                        height="100%"
                        style={{ objectFit: "contain" }}
                      />
                    </Container>
                  </Row>
                }
              </Container>
            </Tab>
          </Tabs>
          <div className="ps-1 mb-3">
            <Button
              variant="primary"
              className="ms-2"
              style={{ color: "white", borderRadius: "25px", minWidth: "112px" }}
              onClick={handleCloseModalImages}
              disabled={imgDataPrincipal === null}
            >
              Confirmar
            </Button>
          </div>
        </Container>
      </Modal>
    </>
  );
};

export const Variants = ({ entityErrors, entity, onControlChange, item }) => {
  const [variants, setVariants] = useState([{
    title: '', hex: '', code: '',
    // variantImg: { imgDataPrincipal: null, imgDataPortada: null, imgsDataGaleria: null, imgPrincipalId: null, imgPortadaId: null }
  }]);

  const addNewVariant = () => {
    setVariants([...variants, {
      title: '', colour: '', code: '', publicPrice: 0, professionalPrice: 0,
      // imgDataPrincipal: null, imgDataPortada: null, imgsDataGaleria: null, imgPrincipalId: null, imgPortadaId: null
      // variantImg: { imgDataPrincipal, imgDataPortada, imgsDataGaleria, imgPrincipalId, imgPortadaId }
    }]);
  };

  const updateVariantImages = (index, { imgDataPrincipal, imgDataPortada, imgsDataGaleria }) => {
    const updatedVariants = variants.map((variant, i) => {
      if (i === index) {
        return {
          ...variant,
          imgDataPrincipal,
          imgDataPortada,
          imgsDataGaleria
        };
      }
      return variant;
    });
    setVariants(updatedVariants);
    onControlChange({ target: { name: 'variants', value: updatedVariants } });
  };

  const removeLastVariant = () => {
    const last = variants.length - 1;
    onControlChange({ target: { name: 'variantsTitle-' + last, value: '' } });
    onControlChange({ target: { name: 'variantsColour-' + last, value: '' } });
    onControlChange({ target: { name: 'variantsCode-' + last, value: '' } });
    onControlChange({ target: { name: 'variantsPublicPrice-' + last, value: 0 } });
    onControlChange({ target: { name: 'variantsProfessionalPrice-' + last, value: 0 } });
    setVariants(variants.slice(0, variants.length - 1));
  };

  useEffect(() => {
    onControlChange &&
      onControlChange({
        target: { name: 'variants', value: variants },
      });
  }, [variants]);

  const variantsContent = entity.variants;

  useEffect(() => {
    if (variantsContent !== undefined) {
      const newVariants = variantsContent.map((variant) => {
        return {
          title: variant.title,
          colour: variant.colour,
          code: variant.code,
          publicPrice: variant.publicPrice,
          professionalPrice: variant.professionalPrice,
          // message: variant.message
        };
      });

      setVariants(newVariants);
    }
  }, [])

  const localChange = (e, index, type) => {
    const variantsCopy = variants;
    variants[index][type] =
      type === 'code' ? parseInt(e.target.value) : e.target.value;
    setVariants(variantsCopy);
    onControlChange(e);
  };
  console.log(entity);
  // console.log(variants);
  return (
    <div>
      {variants.map(({ title, hex, code, imgDataPrincipal, imgDataPortada, imgsDataGaleria }, index) => (
        <div key={'variants' + index} className=" mb-3 w-100 d-flex flex-row " >
          <label
            className={
              'col-sm-4 col-form-label fs-6 fst-italic ' +
              (index > 0 ? 'opacity-0' : 'opacity-100')
            }
          >
            Variantes
          </label>
          <div className='d-flex flex-column'>
            <div style={{ boxSizing: "border-box" }} className="col-sm-8 mb-3 w-100 d-flex flex-row align-content-center flex-wrap align-items-center justify-content-center">
              <div className="col-sm-4 pe-2 ps-0">
                <SimpleControlText
                  name={"variantsTitle-" + index}
                  errors={entityErrors}
                  data={entity}
                  onChange={(e) => localChange(e, index, 'title')}
                  placeholder={"Titulo"}
                  disabled={entity.variantes === "No tiene variantes"}
                />
              </div>
              <div className="col-sm-4 px-2">
                <SimpleControlText
                  name={"variantsColour-" + index}
                  errors={entityErrors}
                  data={entity}
                  onChange={(e) => localChange(e, index, 'colour')}
                  placeholder={"Color (Hex)"}
                  disabled={entity.variantes === "No tiene variantes"}
                />
              </div>
              <div className="col-sm-4 ps-2 pe-0">
                <SimpleControlText
                  name={"variantsCode-" + index}
                  errors={entityErrors}
                  data={entity}
                  onChange={(e) => localChange(e, index, 'code')}
                  placeholder={"Codigo"}
                  disabled={entity.variantes === "No tiene variantes"}
                />
              </div>
              <div className="col-sm-4 ps-2 pe-0 mt-2">
                <SimpleControlText
                  name={"variantsPublicPrice-" + index}
                  errors={entityErrors}
                  data={entity}
                  onChange={(e) => localChange(e, index, 'publicPrice')}
                  placeholder={"Precio Publico"}
                  disabled={entity.variantes === "No tiene variantes"}
                />
              </div>
              <div className="col-sm-4 ps-2 pe-0 mt-2">
                <SimpleControlText
                  name={"variantsProfessionalPrice-" + index}
                  errors={entityErrors}
                  data={entity}
                  onChange={(e) => localChange(e, index, 'professionalPrice')}
                  placeholder={"Precio Privado"}
                  disabled={entity.variantes === "No tiene variantes"}
                />
              </div>
            </div>
            <AddImg
              entity={entity}
              entityErrors={entityErrors}
              item={item}
              onControlChange={onControlChange}
              updateVariantImages={(images) => updateVariantImages(index, images)}
            ></AddImg>
          </div>
        </div>
      ))
      }
      <ListControl
        addNew={addNewVariant}
        removeLast={removeLastVariant}
        list={variants}
      />
    </div>
  );
};
